import m1 from '../../assets/m1.svg'
import m2 from '../../assets/m2.svg'
import m3 from '../../assets/m2.svg'

const data = [
    {
        url:m1,
        height:45,
        width:45,
        textColor: "white",
        textSize: 12,
        
    },
    {
        url:m2,
        height:45,
        width:45,
        textColor: "white",
        textSize: 12,
        
    },
    {
        url:m3,
        height:45,
        width:45,
        textColor: "white",
        textSize: 12,
        
    },
]

export default data;